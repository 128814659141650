<template>
    <div class="grid crud-demo">
        <Loader v-model="loading" />
        <Helper v-model="productHelper" header="Buscar Producto" :headers="productHeaders" :rows="products" @selected="selectProduct" />
        <Helper v-model="MovementWarehouseHelper" header="Buscar Link de Pago" :headers="MovementWarehouseHeaders" :rows="entities" @selected="selectEntity" />
        <Helper v-model="requisitionHelper" header="Buscar Requisicion" :headers="requisitionHeaders" :rows="entities" @selected="selectRequisition" />
        <Toast />
        <div class="col-12">
            <div class="card">
                <Panel header="Requisicion">
                    <BasicFormToolbar @new="newMovementWarehouse" @cancel="cancel" @save="save"  :actions="['save', 'new']" />
                    <TabView>
                        <TabPanel header="Informacion General">
                            <div class="p-fluid formgrid grid">
                                <Fieldset :toggleable="true" legend="Requisicion" class="field col-12 md:col-6 xl:col-6'">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-2 xl:col-2'">
                                            <FormInputText @search="(requisitionHelper.visible = true)" :readonly="true" :search="true" v-model="entity.id" :valid="itemValidation.validations.id_requisition" label="Requisicion" />
                                        </div>
                                        <FormDropdownComplex
                                            :labelInOption="'id - name'"
                                            :labelInValue="'id - name'"
                                            :wrapperClass="'field col-12 md:col-10 xl:col-10'"
                                            label="Tipo de Requisicion"
                                            v-model="entity.id_requisition_type"
                                            :options="id_requisition_type"
                                            optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Dar de alta en esta ruta: Catalogos/Compras/Tipo de Requisicion'"
                                        />
                                              <!-- <FormDropdownComplex
                                            :labelInOption="'id - name'"
                                            :labelInValue="'id - name'"
                                            :wrapperClass="'field col-12 md:col-6 xl:col-6'"
                                            label="Centro de Costo"
                                            v-model="entity.id_centercoust
"
                                            :options="id_costcenter"
                                            optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Aparece al llenar Grupo'"

                                        /> -->
                                        <!-- <FormInputText :textArea="true" v-model="entity.observation" label="Observaciones" wrapperClass="field col-12 xl:col-12" :valid="validate.validations.comment" /> -->
                                    </div>
                                </Fieldset>
                                <Fieldset :toggleable="true" legend="Informacion del Movimiento " class="field col-12 md:col-5 xl:col-5">
                                    <div class="p-fluid formgrid grid">
                                        <div class="field col-12 md:col-6 xl:col-6'">
                                            <label for="Fecha">Fecha de Requisicion</label>
                                            <FormCalendar :disabled="true" dateFormat="dd/mm/yy" v-model="entity.date_req" :showIcon="true" />
                                        </div>
                                        <!-- <div class="field col-12 md:col-4 xl:col-4'">
                                            <label for="Fecha">Fecha de Material Requerido</label>
                                            <FormCalendar  dateFormat="dd/mm/yy" v-model="entity.date_utilization" :showIcon="true" />
                                        </div> -->
                                        <!-- <FormDropdownComplex
                                            :labelInOption="'id - name'"
                                            :labelInValue="'id - name'"
                                            :wrapperClass="'field col-12 md:col-6 xl:col-6'"
                                            label="Tipo de solicitud"
                                            v-model="entity.id_type_request"
                                            :options="id_type_request"
                                             optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Aparece al llenar Grupo'"

                                        /> -->

                                            <FormDropdownComplex
                                            :labelInOption="'id - name'"
                                            :labelInValue="'id - name'"
                                            :wrapperClass="'field col-12 md:col-6 xl:col-6'"
                                            label="Departamento a entregar"
                                            v-model="entity.id_department_to_deliver
"
                                            :options="id_department_to_deliver"
                                            optionLabel="name"
                                            optionValue="id"
                                            :tooltip="'Aparece al llenar Grupo'"

                                        />
                                    </div>
                                </Fieldset>
                                <Fieldset :toggleable="true" legend="Observaciones " class="field col-12 md:col-12 xl:col-12'">
                                    <div class="p-fluid formgrid grid">
                                        <FormInputText :textArea="true" v-model="entity.observation" label="Observaciones" wrapperClass="field col-12 xl:col-12" :valid="validate.validations.comment" />
                                    </div>
                                </Fieldset>
                            </div>
                            <br />
                            <Fieldset :toggleable="true" legend="Articulos">
                                <BasicFormToolbar :actions="['save', 'new']" @new="newItem" @save="saveItem" />
                                <div class="p-fluid formgrid grid">
                                    <FormInputText
                                        :search="true"
                                        ref="Article"
                                        v-model="ShoppingRequisitionItems.id_article"
                                        label="#"
                                        wrapperClass="field col-12 xl:col-1"
                                        :valid="itemValidation.validations.id"
                                        @search="productHelper.visible = true"
                                        :readonly="true"
                                    />
                                    <FormInputText
                                        v-model="ShoppingRequisitionItems.article"
                                        label="Articulo"
                                        wrapperClass="field col-12 xl:col-4"
                                        :valid="itemValidation.validations.article"
                                        validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0"
                                        :readonly="true"
                                    />
                                    <FormInputText v-model="ShoppingRequisitionItems.article_description" 
                                        label="Descripcion" wrapperClass="field col-12 xl:col-7" 
                                        :valid="itemValidation.validations.article_description"
                                        :readonly="true" />
                                        
                                    <FormInputText
                                        v-model="ShoppingRequisitionItems.mu_name"
                                        label="Unidad de medida"
                                        wrapperClass="field col-12 xl:col-3"
                                        :valid="itemValidation.validations.mu_name"
                                        validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0"
                                        :readonly="true"
                                    />
                                    <FormInputNumber
                                        v-model="ShoppingRequisitionItems.amount"
                                        label="Cantidad"
                                        wrapperClass="field col-12 xl:col-3"
                                        :valid="itemValidation.validations.amount"
                                        validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0"
                                    />
                                    <FormInputNumber
                                        v-model="ShoppingRequisitionItems.amount"
                                        label="Surtido"
                                        wrapperClass="field col-12 xl:col-3"
                                        :valid="itemValidation.validations.amount"
                                        validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0"
                                    />
                                    <FormCalendar wrapperClass="field col-12 xl:col-3" dateFormat="dd/mm/yy" v-model="ShoppingRequisitionItems.date" :showIcon="true" label="Fecha en que se nececita"/>
                                    <FormInputText
                                        v-model="ShoppingRequisitionItems.comments"
                                        label="Uso/Comentario"
                                        wrapperClass="field col-12 xl:col-12"
                                        :valid="itemValidation.validations.comments"
                                        validation="Fabor de llenar el campo, la cantidad debe ser mayor a 0"
                                    />
                                    <div class="col-12">
                                        <BasicDatatable @selected="selectItem" :headers="itemHeaders" :rowaction="true" :rowdelete="true" :selectionMode="'single'" :dataKey="'number'" @deleted="deleteItem" :rows="entity.items" :rowedit="true" @edited="editItem" />
                                      
                                    </div>
                                </div>
                            </Fieldset>
                        </TabPanel>
                    </TabView>
                </Panel>
            </div>
        </div>
    </div>
</template>

<script>
/*eslint no-mixed-spaces-and-tabs: ["error", "smart-tabs"]*/
import Loader from '../../../components/general/Loader.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';
import FormCalendar from '../../../components/general/FormCalendar.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import sessionMixin from '../../../mixins/sessionMixin';
import { ShoppingRequisition } from '../../../models/compras/ShoppingRequisition';
import { ShoppingRequisitionItems } from '../../../models/compras/ShoppingRequisitionItems';
import { ErrorToast, fillObject, HeaderGrid, Rule, Toast, validate } from '../../../utilities/General';
//import { InventoryArticle } from '../../../models/inventarios/InventoryArticle';
import { ShoppingRequisitionType } from '../../../models/compras/ShoppingRequisitionType';
import { InventoryConfiguration } from '../../../models/inventarios/InventoryConfiguration';
import { AccountingCostCenter } from '../../../models/contabilidad/AccountingCostCenter';

var math = require('mathjs');
export default {
    mixins: [sessionMixin],
    props: {
        id: null,
    },
    data() {
        return {
            entity: new ShoppingRequisition(this.session),
          //  entityUEPS: new ShoppingRequisitionUEPS(this.session),
            ShoppingRequisitionItems: new ShoppingRequisitionItems(this.session),
        //    ShoppingRequisitionItemsUEPS: new ShoppingRequisitionItemsUEPS(this.session),

            loading: false,
            requisitionHelper:{
                visible: false,
            },
            MovementWarehouseHelper: {
                visible: false,
            },
            productHelper: {
                visible: false,
            },
            movimientos: [],
            products: [],
            MovementWarehouseHeaders: [new HeaderGrid('#', 'id'), new HeaderGrid('Observaciones', 'observation'), new HeaderGrid('Fecha Requisicion', 'date_req',{ type: 'date' }), new HeaderGrid('Fecha Requerida', 'date_utilization', { type: 'date' })],
            productHeaders: [
                new HeaderGrid('Numero de ARTICULO', 'id'), 
                new HeaderGrid('Nombre', 'key_name'), 
                new HeaderGrid('Descripcion', 'name'), 
                new HeaderGrid('Unidad de medida', 'mu_name'), 
                //new HeaderGrid('P.U.', 'unit_price', { type: 'currency' })
            ],
            headers: [
                new HeaderGrid('ID', 'id'),
                new HeaderGrid('Cliente', 'customerInformation_name'),
                new HeaderGrid('Descripcion', 'MovementWarehouseInformation_description'),
                new HeaderGrid('Total a Pagar', 'orderInformation_amountDetails_totalAmount', { type: 'currency' }),
                new HeaderGrid('Fecha de Importe Efectuado', 'created', { type: 'date' }),
                new HeaderGrid('Dias Pendientes', 'dias_pendientes', { formula: 'today_difference', expression: 'MovementWarehouseInformation_dueDate' }),
                new HeaderGrid('Saldo Pagado', 'auth_amount', { type: 'currency', function: 'SUM' }),
                // new HeaderGrid('Saldo Pendiente', 'sal_pendiente',{type: 'currency', function: 'SUM'}),
                //  new HeaderGrid('Estatus', 'status')
            ],

            itemValidation: {
                valid: false,
                validations: {
                    id_requisition: null,
                    article: null,
                    article_description: null,
                    mu: null,
                    mu_name: null,
                    comments: null,
                    amount: null,
                    variety: null,
                    date: null,
                },
            },

            itemRules: [
                // new Rule({ date: 'date' }),
                // new Rule({ comments: 'comments' }),
                //new Rule({ id_requisition: 'id_requisition' }),
            ],
            rules: [new Rule({ name: 'date_req' }), new Rule({ name: 'id_requisition_type' }), new Rule({ name: 'observation' }),],
            validate: {
                valid: false,
                validations: {
                    date_mov: null,
                    id_movementtype: null,
                    id_movement: null,
                    comment: null,
                },
            },
            itemHeaders: [
                new HeaderGrid('#', 'id'),
                new HeaderGrid('Articulo', 'article'),
                new HeaderGrid('Descripcion', 'article_description'),
                new HeaderGrid('Unidad de medida', 'mu_name'),
                new HeaderGrid('Cantidad', 'amount'),
                new HeaderGrid('Surtido', 'variety'),
                new HeaderGrid('Fecha en que se requiere', 'date', {type: "date"}),
                new HeaderGrid('Uso/Comentarios', 'comments'),
               
            ],
            requisitionHeaders: [
                new HeaderGrid('Requisicion', 'id'),
            ],
            inventaryHeaders: [ new HeaderGrid('SKU', 'id_article'), new HeaderGrid('Nombre', 'strarticle'), new HeaderGrid('Cantidad', 'quantity')],
            c_Moneda: [],
            entities: [],
            MovementWarehousePays: [],

            id_movementtype: [],
            id_requisition_type: [],
            id_type_request:[],
            id_department_to_deliver:[],
            id_centercoust:[],
            sritems: []
        };
    },

    computed: {
        SUMATO() {
            return this.subtotal + this.impuestos - this.entity.orderInformation_amountDetails_discountAmount + this.entity.orderInformation_freight_amount;
        },
        total() {
            return this.MovementWarehousePays.reduce((a, b) => {
                return math
                    .chain(a)
                    .add(b.auth_amount ?? 0.0)
                    .done();
            }, 0);
        },
        TOTALAP() {
            return this.SUMATO - this.total;
        },
        subtotal() {
            let subtotal = this.entity.items.reduce((a, b) => {
                var itemSubtotal = math.chain(b.quantity).multiply(b.unitPrice).done();
                return math.chain(a).add(itemSubtotal).done();
            }, 0);
            return subtotal ? subtotal : 0.0;
        },
        impuestos() {
            if (this.subtotal == 0 || this.entity.orderInformation_taxDetails_rate == null || this.entity.orderInformation_taxDetails_rate < 0 || this.entity.orderInformation_taxDetails_rate == 0) {
                return 0;
            } else {
                let percentage = math.chain(this.entity.orderInformation_taxDetails_rate).divide(100).done();
                return math.chain(this.subtotal).multiply(percentage).done();
            }
        },
    },
    methods: {
        async selectRequisition(payload){
            this.entity.id = payload.id;
            this.ShoppingRequisitionItems.id_requisition = payload.id;
            this.entity.id_requisition_type = payload.id_requisition_type;
            let dateformat = payload.date_req;
            let dateobject = new Date(dateformat);
            this.entity.date_req = dateobject;
            this.entity.id_department_to_deliver = payload.id_department_to_deliver;
            this.entity.observation = payload.observation;
            this.entity.items = await this.entity.getListId();
            this.entity.status = payload.status;
            console.log("items:" + this.entity.items)
        },
        editItem(payload){
            this.ShoppingRequisitionItems = fillObject(this.ShoppingRequisitionItems, payload);
            let dateformat = payload.date;
            this.ShoppingRequisitionItems.date = new Date(dateformat);
        },
        selectItem(item) {
            this.ShoppingRequisition = fillObject(this.ShoppingRequisition, item);
        },
        copyLink() {
            if (this.entity.paymentLink) {
                navigator.clipboard.writeText(this.entity.paymentLink);
                this.$toast.add(
                    new Toast({
                        summary: 'Link de Pago',
                        detail: 'Se ha copiado el link en el portapapeles',
                    })
                );
            }
        },
        sendWhatsapp() {
            if (this.entity.paymentLink) {
                window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(this.entity.paymentLink));
            }
        },
        searchProduct() {
            let product = this.products.find((x) => x.barcode == this.ShoppingRequisition.barcode);
            console.log(product);
            if (product) this.selectProduct(product);
        },
        newMovementWarehouse() {
            this.entity = new ShoppingRequisition(this.session);
            this.ShoppingRequisition = new ShoppingRequisitionItems(this.session);
        },
        async selectEntity(MovementWarehouse) {
            console.log(MovementWarehouse);
            this.entity = fillObject(this.entity, MovementWarehouse);
        },
        async refresh() {
            this.loading = true;
            try {

                this.id_type_request=[{id:1,name:"SOLICITUD DE COMPRA"},{id:2,name:"REVISION DE STOCK"} ,{id:3,name:"SOLICITUD DE VIATICOS"}]
                this.id_department_to_deliver= [{id:1,name:"ALMACEN"},{id:2,name:"ADMINISTRACION"} ,{id:3,name:"LOGISTICA"}]
               this.id_costcenter= await new AccountingCostCenter(this.session).all()

               this.id_requisition_type = await new ShoppingRequisitionType(this.session).all();
               console.log( this.id_requisition_type)
                this.entity = new ShoppingRequisition(this.session);
               // this.entityUEPS = new ShoppingRequisitionUEPS(this.session);
                this.ShoppingRequisitionItems = new ShoppingRequisitionItems(this.session);
                this.products = await this.entity.getArticle();
                this.entities = await new ShoppingRequisition(this.session).all();
                this.sritems = await new ShoppingRequisitionItems(this.session).all();
                console.log(this.entities);
    
                this.entity.date_mov = new Date();

                       this.configuration = await new InventoryConfiguration(this.session).getCustom();
                var conf = this.configuration[0].value;
                if(conf==1 && conf==2){
                this.movimientos = await new ShoppingRequisition(this.session).getInventary();
                console.log(this.movimientos.id_article);
                }
            //     else {
            //    //        this.movimientos = await new ShoppingRequisitionUEPS(this.session).getInventary();
            //     }
                if (this.id) {
                    this.entity.id = this.id;
                    this.ShoppingRequisition.id = this.id;
                    let retrieved = await this.entity.retrieve();
                    this.entity = fillObject(this.entity, retrieved);
                    this.entity.items = await this.entity.retrieveItem();
                    this.entity.MovementWarehouseInformation_dueDate = new Date(this.entity.MovementWarehouseInformation_dueDate);
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            this.entity.id = await this.entity.getNextId();
        },

        deleteItem(item) {
            try {
                if (item.id) throw 'No se puede eliminar una partida ya guardada';

                let number = item.number;
                this.entity.items = this.entity.items.filter((x) => x.number != item.number);
                this.entity.items.forEach((x) => {
                    if (x.number > number) {
                        x.number = x.number - 1;
                    }
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        selectProduct(product) {
            this.ShoppingRequisitionItems.id_article = product.id;
            this.ShoppingRequisitionItems.article = product.key_name;
            this.ShoppingRequisitionItems.article_description = product.name;
            this.ShoppingRequisitionItems.mu = product.id_inventory_measure_unit;
            this.ShoppingRequisitionItems.mu_name = product.mu_name;
            this.productHelper.visible = false;
        },
        async save() {
            this.loading = true;
            try {
                let mayorid = await this.entity.getNextId();
                this.validate = validate(this.entity, this.rules);
                console.log("Encabezado:"+JSON.stringify(this.entity))
                if (!this.validate.valid) throw 'Favor de validar los campos';
                if (this.entity.id && this.entity.id > 0 && this.entity.id != mayorid) {
                    let MovementWarehouse = await this.entity.update();
                    let updateList = await this.entity.updateList();
                    let index = this.entities.findIndex((x) => x.id == MovementWarehouse.id);
                    this.entity.items = MovementWarehouse.items;
                    this.entity.items = updateList.items;
                    this.entities[index] = {
                        ...this.entity,
                    };
                    this.entity = new ShoppingRequisition(this.session);

                } else {
                    this.entity.id = null;
                    this.entity.status = 1;
                    //Buscamos si ya existe
                    // if (this.entities.find(x => x.MovementWarehouseInformation_number == this.entity.MovementWarehouseInformation_number))
                    //     throw "Ya existe un link de pago con el mismo folio, favor de cambiar el folio del registro actual";

                    let MovementWarehouse = await this.entity.Mov();
                //    this.entityUEPS.date_mov=this.entity.date_mov;
                //    this.entityUEPS.id_movementtype=this.entity.id_movementtype;
                //    this.entityUEPS.id_movement=this.entity.id_movement;
                //    this.entityUEPS.comment=this.entity.comment;
                //    this.entityUEPS.items=this.entity.items

                   
                   
                   
                   
                   
                //    let MovementWarehouseUEPS=await this.entityUEPS.Mov();
                    this.entities.push(MovementWarehouse);
                    this.entity.id = MovementWarehouse.id;
                    this.entity.items = MovementWarehouse.items;
                    // console.log(MovementWarehouseUEPS);
                    this.entity = new ShoppingRequisition(this.session);
                }
                this.$toast.add(
                    new Toast({
                        summary: 'Movimiento',
                        detail: 'Informacion guardada con exito',
                    })
                );
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            this.entity.id = await this.entity.getNextId();
            this.entity.date_req= new Date();
        },
        newItem() {
            this.ShoppingRequisitionItems = new ShoppingRequisitionItems(this.session);
            this.itemValidation.valid = true;
            this.itemValidation.validations;
            //* Limpiar validaciones
            Object.keys(this.itemValidation.validations).forEach((prop) => (this.itemValidation.validations[prop] = null));
        },
        async saveItem() {
            try{
                this.ShoppingRequisitionItems.variety = this.ShoppingRequisitionItems.amount;
                this.ShoppingRequisitionItems.id_requisition = this.entity.id;
                this.itemValidation = validate(this.ShoppingRequisitionItems, this.itemRules);
                if (!this.itemValidation.valid) {
                    if (event) {
                        event.preventDefault();
                    }
                    throw 'Favor de validar los campos';
                }
                if (this.ShoppingRequisitionItems.id && this.ShoppingRequisitionItems.id > 0){
                    console.log(JSON.stringify(this.sritems))
                    let itemupdate = await this.ShoppingRequisitionItems.update();
                    this.$toast.add({
                    severity: "success",
                    summary: "Actualizar",
                    detail: "Informacion actualizada con exito",
                    life: 3000,
                    });
                    let index = this.sritems.findIndex((x) => x.id == this.ShoppingRequisitionItems.id);
                    this.sritems[index] = itemupdate;
                    this.entity.items = await this.entity.getListId();
                    this.ShoppingRequisitionItems = new ShoppingRequisitionItems(this.session);
                }
                else{
                    this.ShoppingRequisitionItems.number = this.ShoppingRequisitionItems.number ? this.ShoppingRequisitionItems.id : this.entity.items.length + 1;
                    this.entity.items.push({
                        ...this.ShoppingRequisitionItems,
                    });
                    this.ShoppingRequisitionItems = new ShoppingRequisitionItems(this.session);
                    this.$refs.sku.focus();
                    this.$toast.add(
                        new Toast({
                            summary: ' Item',
                            detail: 'Item agregado a lista',
                        })
                    );
                }
            }catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
               
        },
        async cancel() {
            // this.loading = true;
            // try {
            //     let response = await this.entity.delete();
            //     console.log(response);
            // } catch (error) {
            //     this.$toast.add(new ErrorToast(error));
            // } finally {
            //     this.loading = false;
            // }
        },
    },
    async mounted() {
        
        //this.entity.date_req=new Date()
        await this.refresh();
        this.entity.date_req= new Date();
        this.ShoppingRequisitionItems.data = new Date();
        this.entity.id = await this.entity.getNextId();
      
    },

    components: {
        Loader,
        BasicDatatable,
        FormInputText,
        FormInputNumber,
        BasicFormToolbar,
        FormDropdownComplex,
        FormCalendar,
        Helper,
    },
};
</script>

<style>
</style>