import axios from "axios";

import { Abstract } from './Abstract'; 
  export class ShoppingRequisitionItems extends Abstract 
  { 
  constructor(session) { 
  super('Shopping/ShoppingRequisitionItems', session) ;

    this.id_article = null;
    this.id_requisition = null;
    this.article = null;
    this.article_description=null;
    this.mu=null;
    this.mu_name=null;
    this.comments=null;
    this.amount=null;
    this.variety=null;
    this.date= new Date();



    }  
    async allitem() {
     
        let response = await axios.get(this.controller + '/allitem', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    
    async getReportPromArt() {
        let response = await axios.get(this.controller + "/ReportPromArt", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }
 } 
 