import axios from "axios";

import { Abstract } from './Abstract'; 
  export class ShoppingRequisition extends Abstract 
  { 
  constructor(session) { 
  super('Shopping/ShoppingRequisition', session) ;

    this.id = null;
    this.id_requisition_type = null; 
    this.date_req = null; 
    this.date_utilization = null; 
    this.observation=null;
    this.id_department_to_deliver=null;
    this.status = null;
    this.name_authorized = null;
    this.items=[];



    }  
    async getCustom() {
        let response = await axios.get(this.controller + "/getCustom", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }

      async getInventary() {
        let response = await axios.get(this.controller + "/getInventary", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }

      async getInventaryxArticle(almacen,subalmacen,sku) {
        let response = await axios.get(this.controller + "/getInventaryxArticle", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                warehouse:almacen,
                subwarehouse:subalmacen,
                article:sku
            }
        });
        return response.data;
      }

      async getproductsxwarehouse(almacen,subalmacen) {
        let response = await axios.get(this.controller + "/getInventaryxWarehouse", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                warehouse:almacen,
                subwarehouse:subalmacen,
              
            }
        });
        return response.data;
      }

    async retrieveItem() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Ingrese un id para encontrar el registro"
        let response = await axios.get(this.controller + '/item/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async Mov() {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller + '/Mov', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async transfenciaWh(almacen,subalmacen) {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller + '/transfenciaWh', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                almacen:almacen,
                subalmacen:subalmacen
            }
        });
        return response.data;
    }



    async allitem() {
     
        let response = await axios.get(this.controller + '/allitem', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async getAdminMov(FechaInicial,FechaFinal) {
        let response = await axios.get(this.controller + "/getAdminMov", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                fechainicial:FechaInicial,
                fechafinal:FechaFinal
            }
        });
        return response.data;
      }

      async getAdminMovUEPS(FechaInicial,FechaFinal) {
        let response = await axios.get(this.controller + "/getAdminMovUEPS", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                fechainicial:FechaInicial,
                fechafinal:FechaFinal
            }
        });
        return response.data;
      }

      async getReportkardex(FechaInicial,FechaFinal) {
        let response = await axios.get(this.controller + "/getReportkardex", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                fechainicial:FechaInicial,
                fechafinal:FechaFinal
            }
        });
        return response.data;
      }
      async getPedidoMaxMin() {
        let response = await axios.get(this.controller + "/getPedidoMaxMin", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }

      async getPedidoMaxMinAlmacen() {
        let response = await axios.get(this.controller + "/getPedidoMaxMinAlmacen", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }
      async getArticle() {
        let response = await axios.get(this.controller + '/getArticle', {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async getNextId() {
        let response = await axios.get(this.controller + "/getNextId", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }

    async getListId() {
        let response = await axios.get(this.controller + '/getListId',{
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                id: this.id
            }
        });
        return response.data;
    }
    async updateList() {
        this.strUsuarioAlta = this.usuario;
        let response = await axios.post(this.controller + '/updateList', this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    // async getAdmin(params){
    //     console.log("Parametros: " + JSON.stringify(params))
    //     let response = await axios.get(this.controller + "/getAdmin", params,{
    //         headers: {
    //             user: this.user,
    //             company: this.id_company,
    //             branch: this.id_branch,
    //         }
    //     });
    //     return response.data;
    // }
    async getAdmin(params) {        console.log(JSON.stringify(params))
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(query))
        let response = await axios.get(this.controller + "/getAdmin" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
    // async update() {
    //     if (this.id == 0 || this.id == "" || this.id == null)
    //         throw "ERROR. Seleccione un registro para actualizar"
    //     this.strUsuarioMod = this.usuario;
    //     let response = await axios.put(this.controller + "/" + this.id, this, {
    //         headers: {
    //             user: this.user,
    //             company: this.id_company,
    //             branch: this.id_branch
    //         }
    //     });
    //     return response.data;
    // }

    async putAuth(params) {        console.log(JSON.stringify(params))
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        console.log(JSON.stringify(query))
        let response = await axios.put(this.controller + "/putAuth" + query, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
 } 
 